// src/App.js or src/LajuMana.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Loader2, ChevronDown, Globe, Server, Clock, Wifi,
  Activity, FileDown, ArrowRight, Zap, Download,
  Box, Cpu, Signal, Share2, AlertTriangle
} from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const API_BASE_URL = 'https://api.lajumana.my';
const RECAPTCHA_SITE_KEY = '6LdrD3sqAAAAANDExjeXpTKa3_NrEN1kJu6rPlf_';
const POLLING_INTERVAL = 1000; // 1 second

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const getGradeStyle = (grade) => {
  if (grade?.startsWith('A')) {
    return {
      bg: 'bg-green-500/10',
      border: 'border-green-500/30',
      text: 'text-green-400',
      glow: 'shadow-[0_0_30px_rgba(34,197,94,0.2)]'
    };
  }
  if (grade?.startsWith('B')) {
    return {
      bg: 'bg-blue-500/10',
      border: 'border-blue-500/30',
      text: 'text-blue-400',
      glow: 'shadow-[0_0_30px_rgba(59,130,246,0.2)]'
    };
  }
  if (grade?.startsWith('C')) {
    return {
      bg: 'bg-yellow-500/10',
      border: 'border-yellow-500/30',
      text: 'text-yellow-400',
      glow: 'shadow-[0_0_30px_rgba(234,179,8,0.2)]'
    };
  }
  return {
    bg: 'bg-red-500/10',
    border: 'border-red-500/30',
    text: 'text-red-400',
    glow: 'shadow-[0_0_30px_rgba(239,68,68,0.2)]'
  };
};

const MetricCard = ({ label, value, icon: Icon, info, highlight, className = '' }) => {
  const style = highlight ? getGradeStyle(highlight) : {};

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`relative overflow-hidden rounded-xl border backdrop-blur-sm transition-all duration-300 
        ${style.border || 'border-blue-500/20'} 
        ${style.bg || 'bg-white/5'}
        ${className}
        hover:bg-white/10`}
    >
      <div className="p-4">
        <div className="flex items-center gap-2 text-sm mb-2">
          <Icon className={`w-4 h-4 ${style.text || 'text-blue-400'}`} />
          <span className="text-blue-300/70">{label}</span>
        </div>
        <div className={`text-2xl font-bold ${style.text || 'text-white'}`}>
          {value}
        </div>
        {info && (
          <div className="text-sm text-blue-300/50 mt-1">
            {info}
          </div>
        )}
      </div>
    </motion.div>
  );
};

const ServerCard = ({ server, isSelected, onClick }) => {
  const isHighSpeed = server.type === '5G' || server.type === 'Fiber';

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`relative px-6 py-4 rounded-xl border transition-all duration-300 ${(server.error ? 'bg-red-500/10 border-red-500/30' : isSelected ? 'bg-blue-500 border-blue-400 text-white shadow-[0_0_30px_rgba(59,130,246,0.3)]' : 'bg-white/5 border-blue-400/20 hover:bg-white/10')
        }`}
    >
      <div className="flex items-center gap-3">
        {isHighSpeed ? (
          <Zap className={`w-5 h-5 ${isSelected ? 'text-white' : 'text-blue-400'}`} />
        ) : (
          <Signal className={`w-5 h-5 ${isSelected ? 'text-white' : 'text-blue-400'}`} />
        )}
        <div className="text-left">
          <div className="font-medium">{server.location}</div>
          <div className="text-sm opacity-70">{server.telco}</div>
          <div className={`text-xs mt-1 ${isSelected ? 'text-white/70' : 'text-blue-400'
            }`}>
            {server.type}
          </div>
        </div>
      </div>
    </motion.button>
  );
};

const ResourceAnalysis = ({ performanceReport }) => {
  const sizeData = Object.entries(performanceReport.metrics.sizeBreakdown)
    .filter(([key]) => key !== 'total' && performanceReport.metrics.sizeBreakdown[key] > 0)
    .map(([key, value]) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1),
      value: value
    }))
    .sort((a, b) => b.value - a.value);

  const COLORS = [
    '#3b82f6', '#22c55e', '#eab308', '#ef4444',
    '#8b5cf6', '#ec4899', '#f97316', '#06b6d4'
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="h-80"
      >
        <h3 className="text-lg font-medium mb-4">Distribution</h3>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={sizeData}
              cx="50%"
              cy="50%"
              innerRadius="60%"
              outerRadius="80%"
              paddingAngle={5}
              dataKey="value"
            >
              {sizeData.map((entry, index) => (
                <Cell
                  key={entry.name}
                  fill={COLORS[index % COLORS.length]}
                  fillOpacity={0.8}
                />
              ))}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-gray-900/90 backdrop-blur-sm px-4 py-2 rounded-lg border border-blue-500/20">
                      <div className="font-medium">{payload[0].name}</div>
                      <div className="text-sm text-blue-300">
                        {formatBytes(payload[0].value)}
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        className="space-y-3"
      >
        <h3 className="text-lg font-medium mb-4">File Sizes</h3>
        {sizeData.map((item, index) => (
          <div
            key={item.name}
            className="bg-white/5 rounded-lg p-3 flex items-center justify-between"
          >
            <div className="flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: COLORS[index % COLORS.length] }}
              />
              <span className="text-blue-300/70">{item.name}</span>
            </div>
            <span>{formatBytes(item.value)}</span>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

const ResourceList = ({ resources }) => {
  const sortedResources = resources
    .sort((a, b) => {
      const durationDiff = parseFloat(b.timing.duration) - parseFloat(a.timing.duration);
      if (durationDiff !== 0) return durationDiff;
      return b.size - a.size;
    })
    .map(resource => ({
      ...resource,
      totalTime: parseFloat(resource.timing.duration) + parseFloat(resource.timing.waitTime)
    }));

  return (
    <div className="mt-6 space-y-4">
      {sortedResources.map((resource, index) => (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.05 }}
          key={index}
          className="bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors"
        >
          <div className="flex justify-between items-start gap-4">
            <div className="flex-1 min-w-0">
              <div className="font-medium truncate">{resource.url}</div>
              <div className="text-sm text-blue-300/70 mt-1 flex items-center gap-4">
                <span className="inline-flex items-center gap-1">
                  <Box className="w-4 h-4" />
                  {resource.type}
                </span>
                <span className="inline-flex items-center gap-1">
                  <FileDown className="w-4 h-4" />
                  {formatBytes(resource.size)}
                </span>
              </div>
            </div>
            <div className="text-right space-y-1">
              <div className="text-sm">
                <span className={
                  `font-medium ${parseFloat(resource.timing.duration) > 1 ? 'text-red-400' :
                    parseFloat(resource.timing.duration) > 0.5 ? 'text-yellow-400' :
                      'text-green-400'
                  }`
                }>
                  {resource.timing.duration}s
                </span>
                <span className="text-blue-300/70"> total</span>
              </div>
              <div className="text-sm text-blue-300/70 flex flex-col gap-1">
                <div>Download: {resource.timing.downloadTime}s</div>
                <div>Wait: {resource.timing.waitTime}s</div>
              </div>
            </div>
          </div>

          <div className="mt-3 h-1 w-full bg-gray-800 rounded-full overflow-hidden">
            <div
              className="h-full bg-blue-500/50"
              style={{
                width: `${(parseFloat(resource.timing.waitTime) / resource.totalTime) * 100}%`,
                marginLeft: `${(parseFloat(resource.timing.startTime) / resource.totalTime) * 100}%`
              }}
            />
            <div
              className="h-full bg-blue-500"
              style={{
                width: `${(parseFloat(resource.timing.downloadTime) / resource.totalTime) * 100}%`,
                marginLeft: `${((parseFloat(resource.timing.startTime) + parseFloat(resource.timing.waitTime)) / resource.totalTime) * 100}%`,
                marginTop: '-4px'
              }}
            />
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const LajuMana = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);
  const [showResources, setShowResources] = useState(false);
  const [pollInterval, setPollInterval] = useState(null);
  const [recaptchaReady, setRecaptchaReady] = useState(false);


  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [pollInterval]);
  // Modified reCAPTCHA script loading
  useEffect(() => {
    let script;
    const loadRecaptcha = () => {
      return new Promise((resolve, reject) => {
        script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
        script.onload = () => {
          window.grecaptcha.ready(() => {
            setRecaptchaReady(true);
            resolve();
          });
        };
        script.onerror = (error) => reject(error);
        document.body.appendChild(script);
      });
    };

    loadRecaptcha().catch(error => {
      console.error('Failed to load reCAPTCHA:', error);
      setError('Failed to load security check. Please refresh the page.');
      clearInterval(pollInterval);
      setPollInterval(null);
    });

    return () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
      setRecaptchaReady(false);
    };
  }, []);

  // Modified executeRecaptcha function
  const executeRecaptcha = async () => {
    if (!recaptchaReady) {
      throw new Error('Security check not ready. Please try again.');
    }

    try {
      const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
        action: 'submit'
      });

      if (!token) {
        throw new Error('Failed to get security token');
      }

      return token;
    } catch (error) {
      console.error('reCAPTCHA execution error:', error);

      // Reset reCAPTCHA if there's a browser error
      try {
        await window.grecaptcha.reset();
      } catch (resetError) {
        console.error('Failed to reset reCAPTCHA:', resetError);
      }

      throw new Error('Security check failed. Please try again.');
    }
  };

  const pollTestResult = useCallback(async (testId) => {
    try {
      const resultResponse = await fetch(`${API_BASE_URL}/test/result/${testId}`);
      if (!resultResponse.ok) throw new Error('Failed to get results');

      const data = await resultResponse.json();

      if (data.status === 'completed') {
        setResult(data);
        // check if error is not present on first server
        // if (!data.results[0]?.error) {
        setSelectedServer(data.results[0]?.serverId);
        //}
        setLoading(false);

        clearInterval(pollInterval);
        setPollInterval(null);

      }
    } catch (error) {
      console.error('Polling error:', error);
      setError('Failed to get test results');
      setLoading(false);

      clearInterval(pollInterval);
      setPollInterval(null);

    }
  }, [pollInterval]);

  // Modified runTest function
  const runTest = async (e) => {
    e.preventDefault();
    if (!url || loading) return;

    var prepend = '';

    // if url does not start with https:// or http://, add https:// to textbox and variable
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      setUrl(`https://${url}`);
      prepend = 'https://';
    }

    setLoading(true);
    setError(null);
    setResult(null);
    setSelectedServer(null);
    setShowResources(false);

    try {
      let recaptchaToken;
      try {
        recaptchaToken = await executeRecaptcha();
      } catch (recaptchaError) {
        setError(recaptchaError.message);
        setLoading(false);
        clearInterval(pollInterval);
        setPollInterval(null);
        return;
      }

      if (!recaptchaToken) {
        setError('Security check failed. Please try again.');
        setLoading(false);
        clearInterval(pollInterval);
        setPollInterval(null);
        return;
      }

      const testResponse = await fetch(`${API_BASE_URL}/test/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          url: prepend + url,
          isPremium: true,
          recaptchaToken
        }),
      });

      if (!testResponse.ok) {
        const errorData = await testResponse.json();
        throw new Error(errorData.details || errorData.error || 'Failed to start test');
      }

      const responseData = await testResponse.json();
      if (!responseData.testId) {
        throw new Error('Invalid response from server');
      }

      // Start polling
      const interval = setInterval(() => pollTestResult(responseData.testId), POLLING_INTERVAL);
      setPollInterval(interval);

    } catch (error) {
      console.error('Test error:', error);
      setError(error.message || 'Failed to complete test. Please try again.');
      clearInterval(pollInterval);
      setPollInterval(null);
      setLoading(false);
    }
  };

  const selectedResult = result?.results.find(r => r.serverId === selectedServer);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-950 to-black text-white">
      <div className="container mx-auto px-4 pb-8">
        {/* Logo and Title */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="pt-32 pb-16 text-center"
        >
          <h1 className="text-4xl md:text-6xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent mb-4">
            LajuMana.my
          </h1>
          <h2 className="hidden md:block text-xl text-blue-200">Test your web performance - Simultaneously from multiple locations! (In Malaysia!)</h2>
          <h2 className="text-md md:hidden text-blue-200">Test your web performance<br />Simultaneously from multiple locations!<br />(In Malaysia!)<br /><br /><a href="https://bayam.my" className="hover:text-blue-300" target="_blank" rel="noopener noreferrer">
            Powered by Bayam
          </a></h2>
          <h2 className="hidden md:block text-xl text-blue-200 mt-2">
            <a href="https://bayam.my" className="hover:text-blue-300" target="_blank" rel="noopener noreferrer">
              Powered by Bayam
            </a>
          </h2>
        </motion.div>

        {/* Search Box */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-2xl mx-auto"
        >
          <form onSubmit={runTest} className="relative">
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter website URL..."
              className="w-full px-6 py-4 rounded-full bg-white/10 border border-blue-400/30 text-lg text-blue-100 placeholder-blue-300/50 focus:outline-none focus:border-blue-400 focus:ring-2 focus:ring-blue-400/20"
              disabled={loading}
            />
            <button
              type="submit"
              disabled={loading || !url}
              className="absolute right-3 top-1/2 -translate-y-1/2 px-6 py-2 rounded-full bg-blue-500 hover:bg-blue-400 text-white font-medium transition-colors disabled:bg-blue-800/50 disabled:text-blue-200/50"
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="w-5 h-5 animate-spin" />
                  <span>Testing...</span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Activity className="w-5 h-5" />
                  <span>Test</span>
                </div>
              )}
            </button>
          </form>
        </motion.div>

        {/* Results Section */}
        <AnimatePresence>
          {result && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="mt-16 max-w-6xl mx-auto space-y-8"
            >
              {/* Website Info & Screenshot */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white/5 rounded-xl p-6 border border-blue-500/20"
              >
                <div className="flex items-center gap-4 mb-6">
                  <Globe className="w-6 h-6 text-blue-400" />
                  <div>
                    {result.title && (
                      <h3 className="text-xl font-medium truncate">{result.title}</h3>
                    )}

                    <h4 className="text-md text-blue-300/70">URL: {result.url}</h4>

                    <h4 className="text-md text-blue-300/70">Hosting: {result.hosting}
                      {result.recommended_hosting && (
                        <span className="text-blue-300/70"> (Recommended!)</span>
                      )}
                    </h4>
                  </div>
                </div>

                {result.screenshot && (
                  <div className="relative rounded-xl overflow-hidden bg-gray-900 border border-blue-500/20">
                    <img
                      src={`data:image/jpeg;base64,${result.screenshot}`}
                      alt="Website Screenshot"
                      className="w-full object-cover"
                    />
                  </div>
                )}
              </motion.div>

              {/* Server Selection */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className="flex items-center gap-2 mb-4">
                  <Server className="w-5 h-5 text-blue-400" />
                  <h3 className="text-lg font-medium">Test Servers</h3>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {result.results.map((serverResult) => (
                    <ServerCard
                      key={serverResult.serverId}
                      server={serverResult}
                      isSelected={selectedServer === serverResult.serverId}
                      onClick={() => setSelectedServer(serverResult.serverId)}
                    />
                  ))}
                </div>
              </motion.div>

              {/* Server Details */}
              {(selectedResult && !selectedResult.error) && (

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-8"
                >
                  {/* Key Metrics */}
                  <div className="bg-white/5 rounded-xl p-6 border border-blue-500/20">
                    <div className="flex items-center gap-2 mb-6">
                      <Activity className="w-5 h-5 text-blue-400" />
                      <h3 className="text-lg font-medium">Performance Results</h3>
                    </div>

                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                      <MetricCard
                        label="Grade"
                        value={selectedResult.performanceReport.grade}
                        icon={Activity}
                        highlight={selectedResult.performanceReport.grade}
                        className="sm:col-span-2 lg:col-span-1"
                      />
                      <MetricCard
                        label="Performance Score"
                        value={`${selectedResult.performanceReport.score}/100`}
                        icon={Activity}
                        info="Overall performance rating"
                      />
                      <MetricCard
                        label="First Load"
                        value={`${selectedResult.performanceReport.firstLoadTime}s`}
                        icon={Clock}
                        info="Time to first meaningful content"
                      />
                      <MetricCard
                        label="Complete Load"
                        value={`${selectedResult.performanceReport.completeLoadTime}s`}
                        icon={Clock}
                        info="Total page load time"
                      />
                      <MetricCard
                        label="Total Size"
                        value={formatBytes(selectedResult.performanceReport.metrics.sizeBreakdown.total)}
                        icon={FileDown}
                        info="Total downloaded resources"
                      />
                      <MetricCard
                        label="Requests"
                        value={selectedResult.performanceReport.metrics.totalRequests}
                        icon={Share2}
                        info="Number of resource requests"
                      />
                      <MetricCard
                        label="Network"
                        value={selectedResult.telco}
                        icon={Wifi}
                        info={`${selectedResult.type} - ${selectedResult.location}`}
                        highlight={selectedResult.type === '5G' || selectedResult.type === 'Fiber' ? 'A+' : null}
                      />
                    </div>
                  </div>

                  {/* Resource Analysis */}
                  <div className="bg-white/5 rounded-xl p-6 border border-blue-500/20">
                    <div className="flex items-center gap-2 mb-6">
                      <Box className="w-5 h-5 text-blue-400" />
                      <h3 className="text-lg font-medium">Resource Analysis</h3>
                    </div>
                    <ResourceAnalysis performanceReport={selectedResult.performanceReport} />
                  </div>

                  {/* Resource Details */}
                  <div className="bg-white/5 rounded-xl p-6 border border-blue-500/20">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <FileDown className="w-5 h-5 text-blue-400" />
                        <h3 className="text-lg font-medium">Resource Details</h3>
                      </div>
                      <button
                        onClick={() => setShowResources(!showResources)}
                        className="flex items-center gap-2 text-blue-400 hover:text-blue-300"
                      >
                        <ChevronDown
                          className={`transform transition-transform ${showResources ? 'rotate-180' : ''}`}
                        />
                        {showResources ? 'Hide' : 'Show'} Details
                      </button>
                    </div>
                    <AnimatePresence>
                      {showResources && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                        >
                          <ResourceList resources={selectedResult.performanceReport.resources} />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </motion.div>
              )}

              {selectedResult && selectedResult.error && (
                <div className="bg-red-500/10 rounded-xl p-6 border border-red-500/20 mt-6">
                  <div className="flex items-center gap-3 mb-4">
                    <AlertTriangle className="w-6 h-6 text-red-400" />
                    <h3 className="text-lg font-medium text-red-400">Test Failed</h3>
                  </div>

                  <div className="space-y-4">
                    <p className="text-red-300">
                      The performance test failed for this server location. This could be due to:
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-red-300/80">
                      <li>Network connectivity issues</li>
                      <li>Server timeout</li>
                      <li>Website not accessible from this location</li>
                      <li>Temporary service disruption</li>
                    </ul>

                    <div>
                      <div className="font-medium text-red-400 mb-1">Error Details:</div>
                      <div className="text-sm text-red-300/80">{selectedResult.error || 'An unknown error occurred'}</div>
                    </div>
                  </div>
                </div>
              )}
            </motion.div>
          )}

        </AnimatePresence>

        {/* Error Message */}
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="mt-8 max-w-2xl mx-auto text-red-400 bg-red-900/20 px-6 py-3 rounded-lg text-center border border-red-500/20"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Footer */}
        <div className="mt-16 text-center text-blue-400/60">
          <a href="https://bayam.my" className="hover:text-blue-300" target="_blank" rel="noopener noreferrer">
            Your server seems slow? Use Bayam Managed Hosting!
          </a>
        </div>
      </div>
    </div >
  );
};

export default LajuMana;